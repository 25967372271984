<template>
  <main>
    <section
      class="z-30 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-20
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 md:w-2/3 w-full mx-auto z-50">
          <section class="px-4 py-10 bg-white mx-3 shadow rounded sm:p-10">
            <div
              @click="$emit('close-edit-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>
            <section class="space-y-1 sm:mt-2 mt-2">
              <h2 class="leading-relaxed text-xl text-left sm:w-1/3">
                Edit Pet Profile
              </h2>
            </section>
            <section class="sm:w-5/6 sm:mx-auto">
              <section class="pt-6 pb-10 px-0 xl:px-14 space-y-4">
                <div
                  class="
                    grid grid-cols-1
                    xl:grid-cols-3
                    gap-y-10
                    items-center
                    w-full
                  "
                >
                  <div class="col-span-1 place-self-center">
                    <div class="flex justify-center sm:mr-10 mt-6">
                      <pet-icon
                        :width="'w-40'"
                        :height="'w-40'"
                        class="text-gray-300"
                      ></pet-icon>
                    </div>
                  </div>
                  <div class="space-y-6 col-span-2 xl:ml-16">
                    <div class="space-y-6">
                      <input-pet-type
                        v-on:add-pet-type="petType"
                        :pet_type_db="pet_details.pet_type"
                      ></input-pet-type>
                      <input-text
                        placeholder="pet name"
                        v-model="pet_details.pet_name"
                      ></input-text>
                    </div>
                  </div>
                </div>
              </section>

              <section
                class="
                  pt-8
                  pb-10
                  px-0
                  xl:px-14
                  border-b border-t border-gray-200
                "
              >
                <h1 class="text-lg mb-5">Bio Data</h1>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-8">
                  <div>
                    <input-text
                      placeholder="Breed"
                      required
                      v-model="pet_details.pet_breed"
                    ></input-text>
                  </div>
                  <div>
                    <input-text
                      placeholder="Colour"
                      required
                      v-model="pet_details.pet_colour"
                    ></input-text>
                  </div>
                  <div>
                    <input-gender
                      v-on:add-gender="petGender"
                      :pet_gender_db="pet_details.pet_gender"
                    ></input-gender>
                  </div>
                  <div class="relative">
                    <input-text
                      placeholder="weight"
                      v-model="pet_details.pet_weight"
                      required
                    ></input-text>
                    <div class="absolute text-lg font-medium top-3 right-16">
                      lbs
                    </div>
                  </div>
                  <div>
                    <datetime
                      v-model="pet_details.pet_date_of_birth"
                      class="
                        border-b border-nadis-ash2
                        w-full
                        px-2
                        py-3
                        font-medium
                        bg-nadis-bg2
                        focus:outline-none
                      "
                      placeholder="DD MM YYYY"
                      required
                    ></datetime>
                  </div>
                  <div>
                    <input-altered
                      v-on:add-unit="petAltered"
                      :pet_altered_from_db="pet_details.pet_altered"
                    ></input-altered>
                  </div>
                  <div>
                    <input-disabilities
                      v-on:add-unit="petDisabilities"
                      :pet_disabilities_status_db="pet_details.pet_disabilities"
                    ></input-disabilities>
                  </div>
                  <div>
                    <input-text
                      v-if="pet_disabilities_status_db == 'Yes'"
                      placeholder="what kind of disabilities"
                      v-model="pet_disabilities_info"
                    ></input-text>
                  </div>
                </div>
              </section>
              <section class="pt-8 pb-10 px-0 xl:px-14 space-y-6">
                <div>
                  <h1 class="text-lg mb-4">Pet insurance</h1>
                  <div class="flex">
                    <h1 class="text-lg">Does this pet have an insurance?</h1>
                    <div
                      v-for="(insurance, index) in insurance_checkbox"
                      :key="index"
                      class="mb-4 ml-3"
                    >
                      <input-checked
                        :checked="insurance.checked"
                        :unchecked="insurance.unchecked"
                        v-on:checked-value="petInsurance"
                      ></input-checked>
                    </div>
                  </div>
                </div>
                <div class="space-y-6" v-if="pet_details.pet_insurance == true">
                  <input-text
                    placeholder="Company's Name"
                    v-model="pet_details.insurance_name"
                  ></input-text>
                  <input-text
                    placeholder="Policy number"
                    v-model="pet_details.insurance_policy_number"
                  ></input-text>
                  <input-text
                    placeholder="Phone"
                    v-model="pet_details.insurance_phone"
                  ></input-text>
                </div>
              </section>
              <section class="w-full flex items-center">
                <div class="mx-auto">
                  <button
                    class="
                      block
                      py-3
                      px-11
                      sm:px-16
                      text-white
                      bg-primary
                      rounded-md
                    "
                    @click="submitEdit"
                  >
                    save
                  </button>
                </div>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import { Datetime } from 'vue-datetime'
import closeIcon from '../Icons/closeIcon.vue'
import InputGender from '../Utilities/InputGender.vue'
import InputPetType from '../Utilities/inputPetType.vue'
import InputText from '../Utilities/InputText.vue'
import InputDisabilities from '../Utilities/inputDisabilities.vue'
import InputAltered from '../Utilities/inputAltered.vue'
import InputChecked from '../Utilities/inputChecked.vue'
import Swal from 'sweetalert2'
import PetIcon from '../Icons/petIcon.vue'
export default {
  components: {
    closeIcon,
    InputText,
    InputPetType,
    InputGender,
    Datetime,
    InputDisabilities,
    InputAltered,
    InputChecked,
    PetIcon
  },
  props: {
    edit_pet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      pet_disabilities_info: '',
      pet_disabilities_status_db: '',
      pet_altered_from_db: '',
      pet_gender_db: '',
      pet_type_db: '',
      insurance_checkbox: [
        {
          checked: false,
          unchecked: true
        }
      ],
      pet_details: {
        pet_name: this.edit_pet.pet_info.pet_name,
        pet_type: this.edit_pet.pet_info.pet_type,
        pet_breed: this.edit_pet.pet_info.pet_breed,
        pet_colour: this.edit_pet.pet_info.pet_colour,
        pet_gender: this.edit_pet.pet_info.pet_gender,
        pet_weight: this.edit_pet.pet_info.pet_weight,
        pet_date_of_birth: this.edit_pet.pet_info.pet_date_of_birth,
        pet_altered: this.edit_pet.pet_info.pet_altered,
        pet_disabilities: this.edit_pet.pet_info.pet_disabilities,
        pet_insurance: this.edit_pet.pet_info.pet_insurance,
        insurance_name: this.edit_pet.pet_info.insurance_name,
        insurance_policy_number: this.edit_pet.pet_info.insurance_policy_number,
        insurance_phone: this.edit_pet.pet_info.insurance_phone
      }
    }
  },
  watch: {
    pet_type_db(val) {
      this.pet_details.pet_type = val
    },
    pet_altered_from_db(val) {
      this.pet_details.pet_altered = val
    },
    pet_gender_db(val) {
      this.pet_details.pet_gender = val
    },
    pet_name_db(val) {
      this.pet_details.pet_name = val
    },
    pet_disabilities_status_db(val) {
      if (val == 'None') {
        this.pet_details.pet_disabilities = val
      }
    },
    pet_disabilities_info(val) {
      if (this.pet_disabilities_status_db == 'Yes') {
        this.pet_details.pet_disabilities = val
      }
    }
  },
  methods: {
    petType(val) {
      this.pet_type_db = val
    },
    petGender(val) {
      this.pet_gender_db = val
    },
    petInsurance(val) {
      this.pet_details.pet_insurance = val.checked
    },
    petAltered(val) {
      this.pet_altered_from_db = val
    },
    petDisabilities(val) {
      this.pet_disabilities_status_db = val
    },
    submitEdit() {
      var updatePet = {
        id: this.edit_pet.id,
        pet: this.pet_details,
        updatedAt: Date.now()
      }
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Save`
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('pets/updatePet', updatePet)

          let timerInterval
          Swal.fire({
            title: 'please wait!',
            html: 'saving changes <b></b>.',
            timer: 2000,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft()
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          })
            .then(result => {
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'saved successfully',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            })
            .then(() => {
              this.$emit('close-edit-modal')
            })
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  }
}
</script>

<style scoped>
.bg_faint {
  background-color: rgba(10, 10, 10, 0.397);
}
</style>
