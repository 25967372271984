<template>
  <main>
    <section
      v-if="view_pet_page != true"
      class="
        flex
        sm:w-98
        w-full
        bg-white
        shadow-sm
        rounded
        px-4
        py-1
        items-center
        align-middle
      "
    >
      <input
        type="search"
        name=""
        id=""
        class="w-full py-1 outline-none text-lg"
        placeholder="Search pets"
        v-model="pet_search"
      />
      <search-icon class="ml-3" :width="'w-5'" :height="'h-5'"></search-icon>
    </section>
    <section class="mt-10">
      <section
        class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
        v-if="view_pet_page != true"
      >
        <div
          class="
            shadow
            bg-white
            py-12
            px-10
            flex flex-col
            justify-center
            text-center
            items-center
            relative
            card
          "
          v-for="(pet, index) in new_pets"
          :key="index"
        >
          <div class="mb-2">
            <pet-icon
              :width="'w-20'"
              :height="'h-20'"
              class="text-gray-300"
            ></pet-icon>
          </div>
          <h1 class="text-lg font-medium leading-8">
            {{ pet.pet_info.pet_name }}
          </h1>
          <small class="text-base leading-7 text-gray-500 text-opacity-80">{{
            pet.client ? pet.client.name : ''
          }}</small>
          <div>
            <span
              v-if="pet.isActive"
              class="
                bg-active_pill_color
                px-3
                py-1
                rounded-3xl
                text-sm
                font-medium
              "
              >Active</span
            >
          </div>
          <div class="absolute top-0 h-1/5 w-full card-top-bar">
            <div class="flex justify-between px-4 py-3">
              <a
                href="javascript:;"
                class="text-gray-400 cursor-pointer hover:text-primary"
                @click="editPetProfile(index)"
              >
                Edit
              </a>
              <a
                href="javascript:;"
                class="text-gray-400 cursor-pointer hover:text-primary"
                @click="viewPetProfile(index)"
              >
                view
              </a>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section>
      <edit-pet-details
        :edit_pet="edit_pet"
        v-if="edit_pet_model == true"
        @close-edit-modal="editPetProfile"
      ></edit-pet-details>
      <pet-profile
        :view_pet_profile="view_pet_profile"
        v-if="view_pet_page == true"
        @previous-page="viewPetProfile"
      ></pet-profile>
    </section>
    <loading-model v-if="isLoading"></loading-model>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import PetIcon from '../../Icons/petIcon.vue'
import EditPetDetails from '../../Edit/EditPetDetails.vue'
import PetProfile from '../pet-profile/PetProfile.vue'
import LoadingModel from '../../Utilities/loadingModel.vue'
import SearchIcon from '../../Icons/searchIcon.vue'
export default {
  components: { EditPetDetails, PetIcon, PetProfile, LoadingModel, SearchIcon },
  data() {
    return {
      edit_pet_model: false,
      view_pet_page: false,
      edit_pet: null,
      view_pet_profile: null,
      new_pets: [],
      pet_search: null
    }
  },
  created() {},
  computed: {
    ...mapState('pets', ['active_pets', 'isLoading'])
  },
  watch: {
    pet_search(val) {
      this.new_pets = []
      this.searchPet(val)
    },

    active_pets() {
      this.new_pets = this.active_pets
    }
  },
  methods: {
    searchPet(val) {
      this.active_pets.forEach(info => {
        var pet_name = info.pet_info.pet_name.toLowerCase()
        var lowercase_val = val.toLowerCase()
        if (pet_name.includes(lowercase_val)) {
          this.new_pets.push(info)
        }
      })
    },

    editPetProfile(val) {
      this.edit_pet = this.active_pets[val]
      this.edit_pet_model = !this.edit_pet_model
    },
    viewPetProfile(val) {
      this.view_pet_profile = this.active_pets[val]
      this.view_pet_page = !this.view_pet_page
      this.$emit('view-pet-profile', this.view_pet_page)
    }
  },
  mounted() {
    this.$store.dispatch('pets/getActivePet')

    if (this.active_pets != undefined) {
      this.new_pets = this.active_pets
    }
  }
}
</script>

<style scoped>
.card:hover {
  @apply bg-gray-50;
  @apply opacity-90;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
}
</style>
