<template>
  <header>
    <section
      class="mb-10 md:flex justify-end items-center md:space-y-0 space-y-5"
    >
      <div class="">
        <router-link
          class="
            bg-primary
            block
            rounded
            hover:shadow-md
            text-white
            px-6
            sm:px-10
            py-2
            transition
            duration-300
            ease-in-out
          "
          to="/reports"
          >Create new report</router-link
        >
      </div>
    </section>
    <section
      class="
        grid grid-cols-1
        md:grid-cols-3
        gap-x-8
        lg:gap-x-12
        gap-y-6
        lg:gap-y-7
      "
    >
      <div
        class="
          bg-light_blue
          w-full
          pt-7
          pb-7
          px-8
          md:px-6
          lg:px-8
          h-full
          space-y-2
          rounded
          shadow
        "
      >
        <div class="flex justify-between">
          <div class="mt-10 space-y-4">
            <h3 class="text-white text-3xl">
              {{ pets.length }} <span v-if="pets.length > 1">Pet</span>
              <span>Pet</span>
            </h3>
            <p class="text-white text-lg opacity-50" v-if="not_active == false">
              {{ active_pets.length }} active
            </p>
            <p class="text-white text-lg opacity-50" v-if="not_active">
              {{ non_active_pets.length }} Non active
            </p>
          </div>
          <div class="block md:hidden lg:block">
            <pet-icon
              :width="'w-20'"
              :height="'h-20'"
              class="text-gray-100"
            ></pet-icon>
          </div>
        </div>
      </div>
      <!-- <div
        class="
          bg-primary
          w-full
          pt-7
          pb-7
          px-8
          md:px-6
          lg:px-8
          h-full
          space-y-2
          rounded
          shadow
        "
      >
        <div class="flex justify-between">
          <div class="mt-2 space-y-5">
            <div>
              <p class="text-white">Total transactions</p>
              <div class="text-white text-3xl">
                2,330,000 <small class="text-base">NGN</small>
              </div>
            </div>
            <p class="text-white text-lg opacity-50">87 transactions</p>
          </div>
          <div class="block md:hidden lg:block">
            <img src="/img/recycle.png" alt="user profile" class="w-24 h-24" />
          </div>
        </div>
      </div>
      <div
        class="
          w-full
          bg-light_blue
          shadow
          pt-7
          pb-7
          pr-5
          md:pb-0
          pl-8
          md:pl-7
          lg:pl-8
        "
      >
        <div class="flex justify-between">
          <div class="mt-2 space-y-5">
            <div>
              <p class="text-white">Debt</p>
              <div class="text-white text-3xl">
                560,000 <small class="text-base">NGN</small>
              </div>
            </div>
          </div>
          <div class="block md:hidden lg:block">
            <img src="/img/barchart.png" alt="user profile" class="w-24 h-24" />
          </div>
        </div>
        <div class="flex md:block lg:flex justify-between">
          <p class="text-white text-lg opacity-50">87 transactions</p>
          <router-link
            class="
              px-2
              md:px-0
              lg:px-2
              py-1
              font-medium
              flex
              rounded-sm
              text-white
            "
            to="/"
          >
            <p>View details</p>
            <arrow-icon class="ml-4"></arrow-icon>
          </router-link>
        </div>
      </div> -->
    </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import PetIcon from '../Icons/petIcon.vue'
// import ArrowIcon from '../Icons/arrowIcon.vue'
// import SearchInput from '../Utilities/SearchInput.vue'
export default {
  components: { PetIcon /* ArrowIcon */ },
  props: {
    not_active: Boolean
  },
  created() {
    this.$store.dispatch('pets/getPets')
    this.$store.dispatch('pets/getActivePet')
    this.$store.dispatch('pets/getNonActivePet')
  },
  computed: {
    ...mapState('pets', ['pets', 'active_pets', 'non_active_pets'])
  }
}
</script>

<style></style>
