<template>
  <main class="">
    <a
      href="javascript:;"
      @click="$emit('previous-page')"
      class="flex space-x-2 text-primary mb-6"
    >
      <arrowback-icon></arrowback-icon>
      <span>back</span>
    </a>
    <section class="xl:grid xl:grid-cols-3 xl:gap-x-12 xl:space-y-0 space-y-8">
      <section class="col-span-2">
        <section class="bg-white pt-3 pb-12 px-4 sm:px-6 md:px-10 rounded">
          <div
            class="
              flex
              align-middle
              items-center
              justify-end
              mb-10
              sm:mb-1
              space-x-6
            "
          >
            <div
              @click="editPetProfile"
              class="p-1 cursor-pointer shadow-sm rounded-full hover:shadow-md"
            >
              <edit-icon
                :color="'text-gray-400 hover:text-black drop-shadow-md'"
                :width="'w-7'"
                :height="'h-7'"
              ></edit-icon>
            </div>
            <!-- <div
              @click="deletePet"
              class="p-1 cursor-pointer shadow-sm rounded-full hover:shadow-md"
            >
              <delete-icon
                :color="'text-gray-400 hover:text-black drop-shadow-md'"
                :width="'w-7'"
                :height="'h-7'"
              ></delete-icon>
            </div> -->
          </div>
          <div class="block sm:flex mb-6">
            <div class="flex justify-center sm:mr-10">
              <pet-icon
                :width="'w-36'"
                :height="'h-36'"
                class="text-gray-300"
              ></pet-icon>
            </div>
            <div
              class="
                text-center
                sm:text-left sm:place-self-center sm:ml-8
                mt-6
                sm:mt-0
              "
            >
              <h1 class="text-2xl leading-10 font-semibold">{{ pet_name }}</h1>
              <p class="text-base capitalize">
                {{ pet_type }}, {{ pet_breed }}
              </p>
              <div class="mt-4">
                <span
                  v-if="pet_isActive != false"
                  class="
                    bg-active_pill_color
                    px-3
                    py-1
                    rounded-3xl
                    text-sm
                    font-medium
                  "
                  >Active</span
                >
                <span
                  v-if="pet_isActive != true"
                  class="bg-gray-300 px-3 py-1 rounded-3xl text-sm font-medium"
                  >Not Active</span
                >
              </div>
            </div>
          </div>
          <more-pet-profile
            :view_pet_profile="view_pet_profile"
          ></more-pet-profile>
          <edit-pet-details
            :edit_pet="view_pet_profile"
            v-if="edit_pet_profile == true"
            @close-edit-modal="editPetProfile"
          ></edit-pet-details>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import ArrowbackIcon from '../../Icons/arrowbackIcon.vue'
// import DeleteIcon from '../Icons/deleteIcon.vue'
import EditIcon from '../../Icons/editIcon.vue'
import EditPetDetails from '../../Edit/EditPetDetails.vue'
import PetIcon from '../../Icons/petIcon.vue'
import MorePetProfile from './MorePetProfile.vue'

export default {
  components: {
    EditIcon,
    // DeleteIcon,
    ArrowbackIcon,
    EditPetDetails,
    PetIcon,
    MorePetProfile
  },
  props: {
    view_pet_profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      edit_pet_profile: false,
      pet_id: this.view_pet_profile.id,
      pet_name: this.view_pet_profile.pet_info.pet_name,
      pet_type: this.view_pet_profile.pet_info.pet_type,
      pet_breed: this.view_pet_profile.pet_info.pet_breed,
      pet_isActive: this.view_pet_profile.isActive
    }
  },
  methods: {
    editPetProfile() {
      this.edit_pet_profile = !this.edit_pet_profile
    },
    deletePet() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('pets/deletePet', this.pet_id)
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
          this.$emit('previous-page')
        }
      })
    }
  }
}
</script>

<style></style>
