<template>
  <main>
    <pet-header
      v-if="view_pet_profile != true"
      :not_active="not_active"
    ></pet-header>
    <section class="mt-8" v-if="view_pet_profile != true">
      <section class="flex justify-between items-center">
        <section>
          <h1 class="text-xl font-medium">Pets</h1>
        </section>
        <section class="flex align-middle items-center">
          <select
            name=""
            id=""
            class="h-8 w-40 px-2 rounded shadow bg-gray-200 mr-4 outline-none"
            v-model="status"
          >
            <option value="2">All</option>
            <option value="1">Active</option>
            <option value="0">Non Active</option>
          </select>
        </section>
      </section>
    </section>
    <section>
      <all-pet
        v-if="all"
        @view-pet-profile="viewProfile"
        class="mt-10"
      ></all-pet>
      <active-pet
        v-if="active"
        @view-pet-profile="viewProfile"
        class="mt-10"
      ></active-pet>
      <non-active-pet
        v-if="not_active"
        @view-pet-profile="viewProfile"
        class="mt-10"
      ></non-active-pet>
    </section>
  </main>
</template>

<script>
import ActivePet from './view/ActivePet.vue'
import AllPet from './view/AllPet.vue'
import NonActivePet from './view/NonActivePet.vue'
import PetHeader from './PetHeader.vue'
export default {
  components: { ActivePet, NonActivePet, PetHeader, AllPet },
  data() {
    return {
      view_pet_profile: false,
      status: 2,
      all: true,
      active: false,
      not_active: false
    }
  },
  watch: {
    status(val) {
      if (val == 2) {
        this.all = true
        this.active = false
        this.not_active = false
      } else if (val == 1) {
        this.all = false
        this.active = true
        this.not_active = false
      } else if (val == 0) {
        this.all = false
        this.active = false
        this.not_active = true
      }
    }
  },
  methods: {
    viewProfile(val) {
      this.view_pet_profile = val
    }
  }
}
</script>

<style scoped></style>
