<template>
  <section class="">
    <section
      class="
        sm:grid sm:grid-cols-11
        border-gray-100 border-t
        sm:px-2
        md:px-6
        py-8
      "
    >
      <div class="sm:col-span-3 sm:mb-0 mb-4">
        <p class="text-base">Owner Details</p>
      </div>
      <div class="sm:col-span-8 px-1 sm:px-0">
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Name
          </div>
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Phone
          </div>
        </div>
        <div class="grid grid-cols-4 mb-4">
          <div class="col-span-2">{{ owners_name }}</div>
          <div class="col-span-2">{{ phone_num }}</div>
        </div>
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Address
          </div>
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Email
          </div>
        </div>
        <div class="grid grid-cols-4">
          <div class="col-span-2">{{ address }}</div>
          <div class="col-span-2">{{ email }}</div>
        </div>
      </div>
    </section>
    <section
      class="
        sm:grid sm:grid-cols-11
        border-gray-100 border-t
        px-1
        sm:px-2
        md:px-6
        py-8
      "
    >
      <div class="sm:col-span-3 sm:mb-0 mb-4"><p>Bio Data</p></div>
      <div class="sm:col-span-8 px-1 sm:px-0">
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Breed
          </div>
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Colour
          </div>
        </div>
        <div class="grid grid-cols-4 mb-4">
          <div class="col-span-2">{{ breed }}</div>
          <div class="col-span-2">{{ color }}</div>
        </div>
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Sex
          </div>
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Weight
          </div>
        </div>
        <div class="grid grid-cols-4 mb-4">
          <div class="col-span-2">{{ sex }}</div>
          <div class="col-span-2">{{ weight }} lbs</div>
        </div>
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Date of birth
          </div>
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Altered
          </div>
        </div>
        <div class="grid grid-cols-4 mb-4">
          <datetime v-model="dob" class="col-span-2"></datetime>
          <div class="col-span-2">{{ altered }}</div>
        </div>
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Disabilities
          </div>
        </div>
        <div class="grid grid-cols-4">
          <div class="col-span-2">{{ disabilities }}</div>
        </div>
      </div>
    </section>
    <section
      class="
        sm:grid sm:grid-cols-11
        border-gray-100 border-t
        sm:px-2
        md:px-6
        py-8
      "
    >
      <div class="sm:col-span-3 sm:mb-0 mb-4"><p>Insurance details</p></div>
      <div class="sm:col-span-8 px-1 sm:px-0">
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Company
          </div>
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Policy number
          </div>
        </div>
        <div class="grid grid-cols-4 mb-4">
          <div class="col-span-2">{{ insurance_company }}</div>
          <div class="col-span-2">{{ insurance_policy_num }}</div>
        </div>
        <div class="grid grid-cols-4 mb-1">
          <div class="col-span-2 text-gray-500 text-opacity-80 text-base">
            Phone
          </div>
        </div>
        <div class="grid grid-cols-4">
          <div class="col-span-2">{{ insurance_phone }}</div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { Datetime } from 'vue-datetime'
export default {
  components: {
    Datetime
  },
  props: {
    view_pet_profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      owners_name: this.view_pet_profile.client.name,
      phone_num: this.view_pet_profile.client.phone,
      address: this.view_pet_profile.client.address,
      email: this.view_pet_profile.client.email,
      breed: this.view_pet_profile.pet_info.pet_breed,
      color: this.view_pet_profile.pet_info.pet_colour,
      sex: this.view_pet_profile.pet_info.pet_gender,
      weight: this.view_pet_profile.pet_info.pet_weight,
      dob: this.view_pet_profile.pet_info.pet_date_of_birth,
      altered: this.view_pet_profile.pet_info.pet_altered,
      disabilities: this.view_pet_profile.pet_info.pet_disabilities,
      insurance_company: this.view_pet_profile.pet_info.insurance_name,
      insurance_phone: this.view_pet_profile.pet_info.insurance_phone,
      insurance_policy_num:
        this.view_pet_profile.pet_info.insurance_policy_number
    }
  }
}
</script>

<style></style>
